import React from "react"
import { Button } from "wellzesta-ui"
import { Formik, Form } from "formik"
import FormField from "../form/FormField"
import styles from "./LogIn.module.css"

import { loginAsCommunity, fetchSessionAndUser } from "lib/auth/authService"
import yup from "../../lib/yup.validations"
import createApolloClient from "lib/apolloClient"
import { cookies, getCurrentOrganizationId } from "lib/auth"
import { useRouter } from "next/router"
import { useSubdomain } from "lib/Subdomain/SubdomainProvider"
import { useApolloClient } from "lib/ApolloClient/ApolloClientProvider"

const CommunityLoginTokenFormSchema = yup.object().shape({
    token: yup.string().required()
})

const CommunityLoginEmailFormSchema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required()
})

const LoginPageForm = () => {
    const router = useRouter()
    const subDomain = useSubdomain()

    const { accessBlocked } = router.query

    const { setApolloClient } = useApolloClient()

    const onSubmitEmailPassword = async ({ email, password }, actions) => {
        try {
            const loginResult = await fetchSessionAndUser(email, password)
            if (loginResult.success) {
                const { slug } = getCurrentOrganizationId()
                router.push(`/${slug}/`)
            }
        } catch (error) {
            actions.setStatus({ apiError: "User and/or Pin invalid!" })
        }
    }

    const onSubmit = async ({ token }, actions) => {
        try {
            const loginResult = await loginAsCommunity(token)
            if (loginResult.success) {
                const { slug } = getCurrentOrganizationId()
                if (loginResult?.settingsConfigured) {
                    setApolloClient(createApolloClient(cookies.get("TOKEN")))
                    router.push(`/${slug}/content`)
                } else {
                    router.push(`/${slug}/settings`)
                }
            }
        } catch (error) {
            actions.setStatus({ apiError: "Invalid token. Try again." })
        }
    }

    return (
        <div className="right-col">
            <div className={styles.card}>
                <div className={styles.title}>
                    {subDomain === "tv" ? "Sign In as Community" : "Sign In"}
                </div>
                {subDomain === "tv" ? (
                    <Formik
                        onSubmit={onSubmit}
                        validationSchema={CommunityLoginTokenFormSchema}
                        initialValues={{ token: "" }}
                    >
                        {({ isSubmitting, status }) => (
                            <Form className={styles.form}>
                                {status?.apiError && (
                                    <div className={styles.alert} role="alert">
                                        {status.apiError}
                                    </div>
                                )}
                                <FormField
                                    label="Token"
                                    name="token"
                                    placeholder="Type the community token"
                                    type="password"
                                    tabIndex="0"
                                />
                                <Button
                                    type="submit"
                                    theme="secondary"
                                    size="md"
                                    disabled={isSubmitting}
                                    tabIndex="0"
                                >
                                    Sign-In
                                </Button>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <Formik
                        onSubmit={onSubmitEmailPassword}
                        validationSchema={CommunityLoginEmailFormSchema}
                        initialValues={{ email: "", password: "" }}
                    >
                        {({ isSubmitting, status }) => (
                            <Form className={styles.form}>
                                <div
                                    hidden={!status?.apiError}
                                    role="alert"
                                    className={styles.alert}
                                >
                                    {status?.apiError}
                                </div>
                                {accessBlocked === "true" && (
                                    <div className={styles.alert} role="alert">
                                        You have no access to Vis+
                                    </div>
                                )}
                                <FormField
                                    label="Email"
                                    name="email"
                                    placeholder="Type your email"
                                    type="email"
                                    tabIndex="0"
                                />
                                <FormField
                                    label="PIN"
                                    name="password"
                                    placeholder="Type your PIN"
                                    type="password"
                                    className="{color: black}"
                                    maxLength="4"
                                    tabIndex="0"
                                />
                                <Button
                                    type="submit"
                                    theme="primary"
                                    size="md"
                                    disabled={isSubmitting}
                                    tabIndex="0"
                                >
                                    Sign-In
                                </Button>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    )
}

export default LoginPageForm
