import React from "react"
import { Helmet } from "react-helmet"

export default function StonlyWidget() {
    return (
        <Helmet>
            <script>{`window.STONLY_WID = "${process.env.NEXT_PUBLIC_WID}";`}</script>
            <script>
                {`
                !function(s,t,o,n,l,y,w,g){s.StonlyWidget||((w=s.StonlyWidget=function(){
                w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).scriptPath=n,w.queue=[],(y=t.createElement(o)).async=!0,
                (g=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),g.onreadystatechange=function(){
                4===g.readyState&&(y.src=n+"stonly-widget.js?v="+(200===g.status?g.responseText:Date.now()),
                    (l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())
            }(window,document,"script","https://stonly.com/js/widget/v2/");`}
            </script>
            <style>
                {`[aria-label="Close Stonly widget"] svg {
          background-color: #646464 !important;
          width: 50px !important;
          height: 50px !important;
          transition: background-color 0.2s ease 0s;
          border-radius: 50% !important;
        `}
            </style>
        </Helmet>
    )
}
