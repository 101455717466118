import { createContext, useContext, useState, useEffect } from "react"
const SubdomainContext = createContext(null)

export const useSubdomain = () => useContext(SubdomainContext)

export const SubdomainProvider = ({ children }) => {
    const [subDomain, setSubDomain] = useState(null)

    useEffect(() => {
        const hostname = window.location.hostname
        const parts = hostname.split(".")
        if (parts.length > 2) {
            setSubDomain(parts[0])
        }
    }, [])

    return (
        <SubdomainContext.Provider value={subDomain}>
            {children}
        </SubdomainContext.Provider>
    )
}
