export const COMMUNITY_LOGIN_QUERY = `{
    currentUser {
        id
        featureFlagsString
        organization {
            id
            slug
            name
            logoUrl
            timezone
            roles {
                edges {
                    node {
                        id
                        name
                        displayName
                    }
                }
            }
        }
    }
}
`
