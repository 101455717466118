import axios from "axios"
import { setCurrentOrganization, setToken } from "lib/auth.js"
// Queries
import { COMMUNITY_LOGIN_QUERY } from "lib/queries/communityLogin"
import { EMAIL_AND_PASSCODE_LOGIN_QUERY } from "lib/queries/emailAndPasswordLoginQuery"
import { getCommunityConfiguration } from "lib/indexedb"

export const loginAsCommunity = async (token, autoLogin = false) => {
    try {
        const response = await axios.post(
            process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
            {
                query: COMMUNITY_LOGIN_QUERY
            },
            {
                headers: {
                    Authorization: token || "",
                    "Content-Type": "application/json"
                }
            }
        )
        const { data } = response.data

        const { currentUser } = data

        if (!currentUser) {
            throw new Error("Invalid Community Token")
        }
        setToken(token, autoLogin)
        setCurrentOrganization({
            ...currentUser?.organization,
            community: true,
            autoLoggedIn: autoLogin
        })
        const configuration = await getCommunityConfiguration()
        return { success: true, settingsConfigured: configuration, autoLogin }
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchSessionAndUser = async (email, password) => {
    try {
        const response = await axios.post(
            process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
            {
                operationName: "AuthenticationToken",
                variables: { input: { email, pin: password } },
                query: EMAIL_AND_PASSCODE_LOGIN_QUERY
            },
            {
                headers: {
                    // authorization: token,
                    "Content-Type": "application/json"
                }
            }
        )
        const { data } = response.data
        const { getPinAuthenticationToken } = data
        const { token, user } = getPinAuthenticationToken

        if (window !== undefined && window.StonlyWidget) {
            window.StonlyWidget("identify", user.id, {
                "currentuser.userid": user.id,
                "currentuser.firstname": user.firstName,
                "currentuser.lastname": user.lastName,
                "currentuser.email": user.email
            })
        }
        if (!user) {
            throw new Error("User Not Found!")
        }
        setCurrentOrganization({
            ...user.organization,
            role: user.role
        })
        setToken(token)
        if (data?.errors) {
            throw data.errors
        }
        return { success: true }
    } catch (error) {
        console.error(error)
        throw error
    }
}
