export const _P_HIDE_VIS_2_0_FOOTER_ORGS = "_p_hide_vis_2_0_footer_orgs"
export const VP_P_ENABLE_LOGIN = "vp_p_enable_login"

export const DEFAULT_FEATURE_FLAG_STATE = {
    _p_hide_vis_2_0_footer_orgs:
        typeof window !== "undefined" &&
        window.location.host === "tv.stg.wellzesta.com"
            ? "390"
            : "382",
    vp_p_enable_login: ""
}
