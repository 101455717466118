import React from "react"

import styles from "./LogIn.module.css"
import LoginPageTitle from "./LoginPageTitle"
import LoginPageForm from "./LogInPageForm"

const LogIn = () => {
    return (
        <main className={styles.main}>
            <LoginPageTitle />
            <LoginPageForm />
        </main>
    )
}

export default LogIn
