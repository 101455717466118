export const EMAIL_AND_PASSCODE_LOGIN_QUERY = `mutation AuthenticationToken($input: GetPinAuthenticationToken!) 
      {  getPinAuthenticationToken(input: $input) 
            {
            token
              user {
                id
                firstName
                lastName
                fullName
                email
                roleDisplayName
                organization {
                  id,
                  logoUrl,
                  name,
                  slug,
                  timezone
                }
                role {
                    id,
                    name,
                    displayName
                  }
              }
            }
      }`
