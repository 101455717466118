import { useState, useEffect } from "react"
import { useRouter } from "next/router"
import { getCurrentOrganizationId, isLoggedIn } from "lib/auth"
import { useSubdomain } from "lib/Subdomain/SubdomainProvider"

export function RouteGuard({ children }) {
    const router = useRouter()
    const subDomain = useSubdomain()
    const [authorized, setAuthorized] = useState(false)
    const [isPublic, setIsPublic] = useState(false)

    useEffect(() => {
        if (subDomain) {
            authCheck(router.asPath)

            const hideContent = () => setAuthorized(false)
            router.events.on("routeChangeStart", hideContent)
            router.events.on("routeChangeComplete", authCheck)

            return () => {
                router.events.off("routeChangeStart", hideContent)
                router.events.off("routeChangeComplete", authCheck)
            }
        }
    }, [subDomain])

    function authCheck(url) {
        const publicPaths = ["/", "/community-auto-login"]
        const path = url.split("?")[0]
        const isPublic = publicPaths.includes(path)

        setIsPublic(isPublic)
        if (isLoggedIn()) {
            const { slug } = getCurrentOrganizationId()
            setAuthorized(true)
            if (
                isPublic &&
                getCurrentOrganizationId() &&
                path !== "/community-auto-login"
            ) {
                if (subDomain === "tv") {
                    router.push({ pathname: `/${slug}/content` })
                } else {
                    router.push({ pathname: `/${slug}` })
                }
            }
        } else {
            setAuthorized(false)
            if (!isPublic) {
                router.push({ pathname: `/` })
            }
        }
    }

    return (isPublic || authorized) && children
}
