import React, { createContext, useState, useEffect } from "react"
import { DEFAULT_FEATURE_FLAG_STATE } from "./Constants"
import { useApolloClient } from "lib/ApolloClient/ApolloClientProvider"
import { FEATURE_FLAGS_QUERY } from "./featureFlagsQuery"
export const FeatureFlagsContext = createContext({})

const FeatureFlagsProvider = ({ children }) => {
    const [flags, setFlags] = useState({ ...DEFAULT_FEATURE_FLAG_STATE })

    const { apolloClient } = useApolloClient()

    useEffect(() => {
        setFlags({ ...DEFAULT_FEATURE_FLAG_STATE })
    }, [])

    const fetchFeatureFlags = async () => {
        const { data } = await apolloClient.query({
            query: FEATURE_FLAGS_QUERY
        })
        setFlags(JSON.parse(data?.currentUser?.featureFlagsString))
        try {
            setFlags(JSON.parse(data?.currentUser?.featureFlagsString))
        } catch (error) {
            if (apolloClient) {
                setFlags({ ...DEFAULT_FEATURE_FLAG_STATE })
            }
        }
    }

    useEffect(() => {
        if (apolloClient) {
            fetchFeatureFlags()
        }
    }, [apolloClient])

    return (
        <FeatureFlagsContext.Provider
            value={{
                featureFlags: flags
            }}
        >
            {children}
        </FeatureFlagsContext.Provider>
    )
}

export default FeatureFlagsProvider
