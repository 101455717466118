import React from "react"
import WellzestaLogo from "common/icons/WellzestaLogo"
import styles from "./LogIn.module.css"
import { useSubdomain } from "lib/Subdomain/SubdomainProvider"

const LoginPageTitle = () => {
    const subDomain = useSubdomain()
    return (
        <div className={styles.leftCol}>
            <div className={styles.logo}>
                <WellzestaLogo />
                <span>
                    {subDomain === "tv" ? "Wellzesta TV" : "Wellzesta Vis+"}
                </span>
            </div>
            <span>Promoting health and vitality.</span>
        </div>
    )
}

export default LoginPageTitle
