import { gql } from "@apollo/client"

export const FEATURE_FLAGS_QUERY = gql`
    query GetCurrentUser {
        originalCurrentUser {
            id
        }
        currentUser {
            featureFlagsString
        }
    }
`
