import React, { createContext, useState, useContext, useEffect } from "react"
import { getSettings, addOrUpdateSettings } from "lib/indexedb" // Removed unused deleteSettings import
import content from "./content.json"
import { useError } from "lib/Error/ErrorProvider" // Import the useError hook
import categories from "./settingsCategories.json"
import { isLoggedIn, getCurrentOrganizationId } from "lib/auth"
import ROLE_NAMES from "./roles.json"

import { useSubdomain } from "lib/Subdomain/SubdomainProvider"

// Create context
const SettingsContext = createContext()

// Custom hook to use the context
export const useSettings = () => useContext(SettingsContext)

// Provider component to wrap around the app
export const SettingsProvider = ({ children }) => {
    const loggedIn = isLoggedIn()
    const subDomain = useSubdomain()
    const { triggerError } = useError()
    const [settings, setSettings] = useState(null)
    const [channelType, setChannelType] = useState("")
    const [contentOptions, setContentOptions] = useState([])
    const [roleOptions, setRoleOptions] = useState([])
    const [allRolesEnabled, setAllRolesEnabled] = useState(true)
    const [disablePlayButton, setDisablePlayButton] = useState(false)

    const [settingsCategories, setSettingsCategories] = useState(
        categories?.settingsCategories
    )

    const setRoleSettings = async () => {
        const { roles } = getCurrentOrganizationId()
        const enabledRoles = roles?.edges?.filter(
            (role) =>
                role?.node?.name === ROLE_NAMES.INDEPENDENT_LIVING ||
                role?.node?.name === ROLE_NAMES.ASSISTED_LIVING
        )

        const setRoleSettingsPromises = enabledRoles?.map(async (role) => {
            return await addOrUpdateSettings({
                id: `vis-role-${role?.node?.name.toLowerCase().replace(" ", "-")}`,
                roleId: role?.node?.id,
                type: "role",
                enabled: true,
                name: role?.node?.name,
                displayName: role?.node?.displayName
            })
        })

        await Promise.all(setRoleSettingsPromises)
        return enabledRoles?.map((role) => {
            return {
                id: `vis-role-${role?.node?.name.toLowerCase().replace(" ", "-")}`,
                roleId: role?.node?.id,
                type: "role",
                enabled: true,
                name: role?.node?.name,
                displayName: role?.node?.displayName
            }
        })
    }

    const updateSettings = async (settings) => {
        const promises = settings.map((setting) => {
            let settingObject = {
                id: setting?.id,
                type: setting?.type
            }
            if (
                setting?.type === "multiChannel" ||
                setting?.type === "singleChannel"
            ) {
                settingObject = {
                    ...settingObject,
                    channelName: setting?.channelName,
                    enabled: setting?.enabled
                }
            }
            if (setting?.type === "version") {
                settingObject = {
                    ...settingObject,
                    versionNumber: setting.versionNumber
                }
            }
            return addOrUpdateSettings(settingObject)
        })
        await Promise.all(promises)
    }

    const setSettingsFromJsonFile = async () => {
        await updateSettings(content)
        const roles = await setRoleSettings()
        setSettings([...content, ...roles])
    }

    const fetchSettings = async () => {
        try {
            const alldBSettings = await getSettings()
            const visSettings = alldBSettings?.filter(
                (setting) => setting.id !== "community-configured"
            )
            if (!visSettings.length) {
                await setSettingsFromJsonFile()
            } else {
                const version =
                    visSettings.find(
                        (setting) => setting?.type === "version"
                    ) || 0
                const currentVersion = content.find(
                    (item) => item.type === "version"
                )

                // Filter settings of type "role" where option.id does not include a space
                const roleSettings = visSettings.filter(
                    (setting) =>
                        setting.type === "role" && !setting?.id.includes(" ")
                )

                // Further filter to only include enabled settings
                const enabledRoleSettings = roleSettings.filter(
                    (setting) => setting.enabled
                )

                // Check if there are exactly 2 enabled role settings
                const allRolesEnabled = enabledRoleSettings.length === 2

                // Update the state with the result
                setAllRolesEnabled(allRolesEnabled)

                if (
                    version.versionNumber < currentVersion.versionNumber ||
                    Object.keys(version).length <
                        Object.keys(currentVersion).length
                ) {
                    await setSettingsFromJsonFile()
                } else {
                    setSettings(visSettings)
                }
            }
        } catch (error) {
            triggerError(
                `Error in setting vis settings: ${error.message}`,
                error
            )
        }
    }

    useEffect(() => {
        if (subDomain === "tv") {
            if (loggedIn) {
                fetchSettings()
            }
        }
    }, [loggedIn, subDomain])

    useEffect(() => {
        if (settings) {
            if (settings.length) {
                // set role settings
                setRoleOptions(
                    settings.filter(
                        (option) =>
                            option?.type?.toLowerCase() === "role" &&
                            !option?.id.includes(" ") &&
                            option?.id !== "vis-role-all"
                    )
                )
                if (channelType !== "") {
                    const filteredOptions = settings
                        ? settings.filter(
                              (option) =>
                                  option?.type?.toLowerCase() ===
                                  channelType?.toLowerCase()
                          )
                        : []

                    if (channelType === "multiChannel") {
                        setContentOptions([
                            filteredOptions.find(
                                (option) => option?.channelName === "Messages"
                            ),
                            filteredOptions.find(
                                (option) => option?.channelName === "Menu"
                            ),
                            filteredOptions.find(
                                (option) => option?.channelName === "Events"
                            )
                        ])
                    } else {
                        setContentOptions(filteredOptions)
                        setDisablePlayButton(false)
                    }
                } else {
                    let disabledOptions = 0
                    settings.forEach((option) => {
                        if (
                            !option?.enabled &&
                            option?.type === "multiChannel"
                        ) {
                            disabledOptions += 1
                        }
                    })
                    if (disabledOptions === 3) {
                        setChannelType("singleChannel")
                    } else {
                        setChannelType("multiChannel")
                    }
                }
            }
        }
    }, [settings, channelType])

    useEffect(() => {
        if (contentOptions && roleOptions) {
            if (contentOptions.length && roleOptions.length) {
                let disabledRoleOptions = 0
                let disabledContentOptions = 0
                roleOptions.forEach((option) => {
                    if (!option?.enabled) {
                        disabledRoleOptions += 1
                    }
                })
                if (channelType === "multiChannel") {
                    contentOptions.forEach((option) => {
                        if (!option?.enabled) {
                            disabledContentOptions += 1
                        }
                    })
                }
                if (disabledContentOptions === 3 || disabledRoleOptions === 2) {
                    setDisablePlayButton(true)
                }
                if (disablePlayButton) {
                    if (disabledContentOptions < 3 && disabledRoleOptions < 2) {
                        setDisablePlayButton(false)
                    }
                    if (
                        channelType === "singleChannel" &&
                        disabledRoleOptions < 2
                    ) {
                        setDisablePlayButton(false)
                    }
                }
            }
        }
    }, [contentOptions, roleOptions])

    return (
        <SettingsContext.Provider
            value={{
                channelType,
                setChannelType,
                contentOptions,
                setContentOptions,
                roleOptions,
                setRoleOptions,
                allRolesEnabled,
                setAllRolesEnabled,
                settings,
                setSettings,
                disablePlayButton,
                settingsCategories,
                setSettingsCategories
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}
